import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

const RegionHero = () => {
  const data = useStaticQuery(graphql`
    query {
      headerImage: file(relativePath: {eq: "header_okanagan_post.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <div className="region-hero post-ceremony pos-relative">
        <div className="region-hero__image image-max-width hidden-phone">
          <div className="pos-relative">
              <Img 
                fadeIn={false}
                fluid={data.headerImage.childImageSharp.fluid}
                alt="A group of UBC graduates walk across the Okanagan campus" />
              <div className="image-gradient" />
          </div>
        </div>
        <div className="pos-absolute region-hero__content">
          <div className="bound region-hero__content__bound">
            <div className="region-hero-flex">
              <div className="hero-flex-content"> 
                <h1>Congratulations UBC graduates!</h1>
              </div>
              <div className="hero-flex-image visible-phone">
                <Img 
                  fluid={data.headerImage.childImageSharp.fluid}
                  alt="A group of UBC graduates walk across the Okanagan campus" />
              </div>
            </div>
          </div>
        </div>
    </div>
  )

}
export default RegionHero