/**
 * Retrieve Guestbook entry
 */
import React, { useState, useEffect } from "react"
import axios from "axios"
import useDebounce from "./debounce"
import moment from "moment"

const FETCH_SIZE = 3
// const BASE_URL = "/guestbook/"

// class GuestbookSubmission extends React.Component {
const GuestbookEntry = ({ location }) => {
  // Client-side Runtime Data Fetching
  const [guestBookEntry, setGuestBookEntry] = useState('')   // Entries data
  const [guestBookSize, setGuestBookSize] = useState(0)  // Total size
  const [currentPage, setCurrentPage] = useState(1)  // Page
  const [searchEntry, setSearchEntry] = useState('')  // Search term
  const [isLoading, setIsLoading] = useState(1)  // Loading?
  const [loadMore, setLoadMore] = useState(false) // Load more entries?

  const pageQuery = `paging[page_size]=${FETCH_SIZE}&paging[current_page]=${currentPage}` // Set page to fetch
  let searchQuery = [{"key":9,"value":"Show","operator":"="}]
  let additionalQuery = `&search={"field_filters": [{"key":"${searchQuery[0].key}","value":"${searchQuery[0].value}","operator":"="}]}`
  // let additionalQuery = ``

  // Fetch specific entry by ID or all entries
  // const url = location.pathname ? location.pathname : '' // get page URL, work with below to get entry ID
  // const entryID = isNaN(url.substring(BASE_URL.length))? '' : url.substring(BASE_URL.length)
  // let additionalQuery = (entryID) ? `&search={"field_filters": [{"key":"id","value":${entryID},"operator":"="}]}` : ''
  
  // Search entry (by enter name [key:2] and student name [key:7])
  const debouncedSearchEntry = useDebounce(searchEntry, 1000);
  if (debouncedSearchEntry.trim().length > 1) {
    // additionalQuery = `&search={"field_filters": [{"key":2,"value":"${debouncedSearchEntry}","operator":"contains"}]}` // Single Value search
    // additionalQuery = `&search={"field_filters": {"mode":"any","0":{"key":2,"value":"${debouncedSearchEntry}","operator":"contains"},"1":{"key":7,"value":"${debouncedSearchEntry}","operator":"contains"}}}` // Multiple value "or" search
    additionalQuery = `&search={"field_filters": [{"key":7,"value":"${debouncedSearchEntry}","operator":"contains"},{"key":"${searchQuery[0].key}","value":"${searchQuery[0].value}","operator":"="}]}` // Single Value search with Show flag
  }

  // Page limit
  additionalQuery = `${additionalQuery}&${pageQuery}`

  useEffect(() => {
    axios({
      url: `https://guestbook.graduation.ubc.ca/wp-json/gf/v2/entries?form_ids[0]=1&_field_ids=2,3,4,5,7,id,date_created${additionalQuery}` ,
      method: 'GET',
      credentials: 'include',
      headers: {
        "Authorization": `Basic `+ btoa(`ck_387ac880dae0b157319ca550af54b19d68eacc8d:cs_255ef66a4eb1b5bc23f811d98bd9931ed43b9b98`),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.data) // parse JSON from request
    .then(resultData => {
      setIsLoading(0);

      if (loadMore) {
        // setGuestBookSize(guestBookSize+resultData.total_count)
        setGuestBookEntry(guestBookEntry => guestBookEntry.concat(resultData.entries))
        setLoadMore(false)
      } else {
        setCurrentPage(1);
        setGuestBookSize(resultData.total_count)
        setGuestBookEntry(resultData.entries)
      }
    })
    .catch(err => { })

  }, [additionalQuery])

  // Handle onChange event
  const inputHandler = (e) => {
    setIsLoading(1);
    setSearchEntry(e.target.value)
  }

  const getMore = (e) => {
    setLoadMore(true);

    let toFetch = (currentPage) * FETCH_SIZE;
    if (toFetch < guestBookSize) {
      setCurrentPage(currentPage+1)
    }
  }

  const submissionListing = () => {
    return (
      <div className="guestbook_list">
        <hr />
        {guestBookEntry && (guestBookSize > 0) ? guestBookEntry.map((entries, key) => {
          return <div
              key={key}
              className="guestbook_entries">
              {entries[7] ?<p>To: { entries[7] }</p>: ''}
              <p>{ entries[5] }</p>
              <p><b>{ entries[2] }</b><br />{ entries[3] ? `${entries[3]}, `: ''}{ [entries[4]] }{ entries[3] || entries[4] ? " | ": ""}{ moment(entries.date_created).format('MMMM DD, YYYY') }</p>
              <hr />
          </div>
        }) : (<p className="text-color-white">No guestbook entry found.</p>) }
        { ( (currentPage * FETCH_SIZE) < guestBookSize ) ?<button className="text-color-white link" onClick={getMore}>Load more guestbook entries</button>:''}
      </div>
    )
  }

  const loadingScreen = () => {
    return (
      <div className="loading">
        <p><b className="text-color-white">Finding name...</b></p>
      </div>
    )
  }

  return (
    <div className="guestbook_submission">
        <h3 className="text-color-white">Guestbook Entries</h3>
        <input
          type="text"
          className="guestbook-search"
          aria-label="Search Guestbook"
          value={ searchEntry }
          onChange={inputHandler}
          placeholder="Search by Graduate‘s name"
        />
      { isLoading ? loadingScreen() : submissionListing() }
    </div>
  )
}
export default GuestbookEntry