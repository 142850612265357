import React from "react"

const embedCode = ({ EmbedURL, EmbedCodeTitle, EmbedCodeClass, EmbedCodeWrapperClass, ...props }) => {
  const wrapperClass = (EmbedCodeWrapperClass)? `${EmbedCodeWrapperClass}`: 'responsive-media'
  return (
  <div className={wrapperClass} >
    <iframe 
        className={EmbedCodeClass}
        title={EmbedCodeTitle}
        style={{"border": "none", 'width': '100%'}}
        src={EmbedURL}>
    </iframe>
  </div>
)
}

export default embedCode