import React from "react"
import EmbedCode from "./embed"

import iconFB from "../../static/images/fb.svg"
import iconIG from "../../static/images/instagram.svg"
import iconTwitter from "../../static/images/twitter.svg"

const RegionWakelet = () => {

    return (
        <div className="region-social-feed background-color-main region-padding-large">
            <div className="bound">
                <div className='row-fluid'>
                    <div className="span12">
                        <h2>Share the excitement</h2>
                        <div className="row-fluid">
                            <div className="span6">
                                <p>Let us know how you&rsquo;re celebrating and what makes you proud. Share your experience on social media with <b>#UBCOgrad</b>.</p>
                            </div>
                            <div className="span5">
                                <p><b>Follow UBC on social media.</b></p>
                                <div className="padding-bottom-phone flex wakelet-social">
                                    <a href="https://www.facebook.com/universityofbc?fref=ts"><img src={iconFB} alt="Facebook" /> Facebook</a>
                                    <a href="https://instagram.com/universityofbc"><img src={iconIG} alt="Instagram" /> Instagram</a>
                                    <a href="https://twitter.com/ubc"><img src={iconTwitter} alt="Twitter" /> Twitter</a>
                                </div>
                            </div>
                        </div>
                        <EmbedCode
                            EmbedURL="https://embed.wakelet.com/wakes/9ed22e31-af3f-4c49-a3c3-8ea96648c332/horizontal?hide-description=1"
                            EmbedCodeTitle="Wakelet Embed"
                            EmbedCodeWrapperClass="responsive-media wakelet"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegionWakelet