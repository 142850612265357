import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image/withIEPolyfill"

import coa from "../../static/images/ubc_coat_of_arms.png"

const RegionAcknowledgement = ( ) => {

    // const pageImage = useStaticQuery(graphql`
    //     query {
    //         coatOfArms: file(relativePath: {eq: "ubc_coat_of_arms.png"}) {
    //             childImageSharp {
    //                 fixed(width: 84) {
    //                     ...GatsbyImageSharpFixed
    //                 }
    //             }
    //         }
    //     }
    // `)

    return (
        <div className="region-acknowledgement region-padding-large">
            <div className="bound">
                <p>We acknowledge that UBC&rsquo;s campuses are situated within the traditional territories of the Musqueam, Squamish and Tsleil-Waututh, and in the traditional, ancestral, unceded territory of the Syilx Okanagan Nation and their peoples.</p>
                <div className="text-center">
                    <img 
                        src={coa}
                        alt="UBC Coat of Arms"
                        width="84"
                    />
                    {/* <Img
                    fixed={pageImage.coatOfArms.childImageSharp.fixed}
                    alt="UBC Coat of Arms"
                    /> */}
                </div>
            </div>
        </div>
    )
}
export default RegionAcknowledgement