import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

// import pdfCloud from "../../static/pdf/ubc_virtual_graduation_signs_cloud.pdf"
// import pdfOkanagan from "../../static/pdf/ubc_virtual_graduation_signs_okanagan.pdf"
import pdfProgram from "../../static/pdf/ubco_spring_2021_convocation_program.pdf"

const RegionCeremony = () => {

    const pageImage = useStaticQuery(graphql`
    query {      
      getReady1: file(relativePath: {eq: "get_ready1.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 570) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      getReady2: file(relativePath: {eq: "get_ready2.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 570) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      program: file(relativePath: {eq: "program.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 570) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      honorary: file(relativePath: {eq: "honorary_okanagan.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 570) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      doctoral: file(relativePath: {eq: "doctoral.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 570) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  // congrats: file(relativePath: {eq: "congrats1.jpg"}) {
  //   childImageSharp {
  //     fluid(maxWidth: 570) {
  //       ...GatsbyImageSharpFluid
  //     }
  //   }
  // },
  // getReady3: file(relativePath: {eq: "get_ready3.jpg"}) {
  //   childImageSharp {
  //     fluid(maxWidth: 570) {
  //       ...GatsbyImageSharpFluid
  //     }
  //   }
  // },

    return (
      <div className="region-ceremony region-padding-large">
        <div className="bound">
          <div className="row-fluid">
            <div className="span12">
              <h2>Congratulations Class of 2021!</h2>
            </div>
          </div>
          <div className="row-fluid">
            <div className="span6">
              <h3 className="text-color-unit-primary">Access your commemorative UBC video clip</h3>
              <p>All graduates may download a commemorative graduation video clip. Incorporating images of UBC life and the graduate&rsquo;s name, this personalized clip is a free souvenir for you. If you submitted a multimedia message through StageClip before May 26, your personalized message is now part of this clip.</p>
              <p>Share your video with friends and family on social media and show your UBC pride!</p>
              <p><a href="https://ubco.stageclip.com/">Find my clip <span class="icon-external-link"></span></a></p>
            </div>
            <div className="span6">
              <Img 
                className="image-margin-bottom"
                fluid={pageImage.getReady1.childImageSharp.fluid}
                alt="Screenshot of Stageclip sample"
              />
            </div>
          </div>
          {/* <div className="row-fluid">
            <div className="span6">
              <h3 className="text-color-unit-primary">Celebrate with <em>alumni UBC</em></h3>
              <p>After the graduation ceremony, don't miss UBC Celebrates Together, our virtual graduation reception at 4:10 p.m., presented by <em>alumni UBC</em>.</p>
              <p><a href="https://www.alumni.ubc.ca/2021/events/ubc-celebrates-together-okanagan-spring2021/">Register for the reception</a></p>
            </div>
            <div className="span6">
              <Img 
                className="image-margin-bottom"
                fluid={pageImage.congrats.childImageSharp.fluid}
                alt="A UBC graduate smiling outdoors"
              />
            </div>
          </div> */}
          <div className="row-fluid">
            <div className="span6">
              <h3 className="text-color-unit-primary">Find graduation memorabilia and gifts</h3>
              <p>Purchase a souvenir of graduation or celebrate your favourite graduate with these gifts. Options include diploma frames, faculty-branded apparel, official regalia, and cake delivery.</p>
              <p><a href="https://graduation.ok.ubc.ca/family-guests/">View grad gifts</a></p>
            </div>
            <div className="span6">
              <Img 
                className="image-margin-bottom"
                fluid={pageImage.getReady2.childImageSharp.fluid}
                alt="Family member gives flower bouquet to a UBC graduate"
              />
            </div>
          </div>
          <div className="row-fluid">
            <div className="span6">
              <h3 className="text-color-unit-primary">Get the convocation program</h3>
              <p>Find the names of all 2021 UBC Okanagan graduates and read about UBC&rsquo;s history and graduation traditions.</p>
              <p><a href={pdfProgram} download>Download program</a></p>
            </div>
            <div className="span6">
              <Img 
                className="image-margin-bottom"
                fluid={pageImage.program.childImageSharp.fluid}
                alt="Ceremony program"
              />
            </div>
          </div>
          {/* <div className="row-fluid">
            <div className="span6">
              <h3 className="text-color-unit-primary">Display a celebratory sign</h3>
              <p>Show the world how much you support your UBC graduate! These 8.5 x 11&rdquo; PDF signs can be printed on your home computer.</p>
              <p><a href={pdfOkanagan} download>Download sign: &ldquo;Congratulations on your UBC graduation!&rdquo;</a></p>
              <p><a href={pdfCloud} download>Download sign: &ldquo;Congratulations UBC graduates!&rdquo;</a></p>
            </div>
            <div className="span6">
              <Img 
                className="image-margin-bottom"
                fluid={pageImage.getReady3.childImageSharp.fluid}
                alt="Celebratory UBC Graduation sign hangs in a window"
              />
            </div>
          </div> */}
          <div className="row-fluid">
            <div className="span6">
              <h3 className="text-color-unit-primary">Spotlight on doctoral research</h3>
              <p>The research produced by UBC&rsquo;s Class of 2021 doctoral graduates is changing the world.</p>
              <p><a href="https://gradstudies.ok.ubc.ca/doctoralcitations/">Browse their doctoral citations</a></p>
            </div>
            <div className="span6">
              <Img 
                className="image-margin-bottom"
                fluid={pageImage.doctoral.childImageSharp.fluid}
                alt="A smiling UBC doctoral graduates dressed in regalia"
              />
            </div>
          </div>
          <div className="row-fluid">
            <div className="span6">
              <h3 className="text-color-unit-primary">Meet UBC&rsquo;s 2021 honorary degree recipients</h3>
              <p>Learn about these extraordinary individuals and watch their video messages.</p>
              <p><a href="https://graduation.ok.ubc.ca/event/honorary-degrees/2021-honorary-degree-recipients/">Learn about this year&rsquo;s honorary degree recipients </a></p>
            </div>
            <div className="span6">
              <Img 
                fluid={pageImage.honorary.childImageSharp.fluid}
                alt="UBC Okanagan Honorary Degree recipients"
              />
            </div>
          </div>
        </div>
      </div>
    )
}

export default RegionCeremony